import { Button, Card, Checkbox, Col, Form, Input, InputNumber, Radio, Row, Select, Space, Spin, Statistic, Tag, Typography } from "antd";
import gql from "graphql-tag";
import React, { Fragment, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import { Traffic } from "../Traffic";
import { ResetActions } from "./ResetActions";
import * as gd from "./__generated__/DeleteModem";
import * as g from "./__generated__/Modem";
import * as gr from "./__generated__/SearchPowerResetCommandModem";
import * as gs from "./__generated__/SyncModem";
import * as gu from "./__generated__/UpdateModem";

const GET_MODEM = gql`
  query Modem($id: ID!) {
    modem(id: $id) {
      id
      iface
      proxyPort
      webHost
      webLogin
      webPassword
      externalIp
      manufacturer
      status
      wanIp
      resetType
      comment
      mikrotikIp
      mikrotikLogin
      mikrotikPassword
      mikrotikCommand
      mikrotikOverride
      ports {
        id
      }
      pools {
        id
        name
      }
      iProxy
      iProxyData {
        id
        proxyUrl
        changeIpUrl
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_MODEM = gql`
  mutation UpdateModem($id: ID!, $input: ModemUpdateInput!) {
    updateModem(id: $id, input: $input) {
      id
      iface
      proxyPort
      webHost
      webLogin
      webPassword
      externalIp
      manufacturer
      status
      wanIp
      resetType
      mikrotikIp
      mikrotikLogin
      mikrotikPassword
      mikrotikCommand
      mikrotikOverride
      createdAt
      updatedAt
    }
  }
`;

const DELETE_MODEM = gql`
  mutation DeleteModem($id: ID!) {
    deleteModem(id: $id)
  }
`;

const MODEM_TRAFFIC = gql`
  query Traffic($id: ID!, $from: Time!, $to: Time!, $kind: String!) {
    traffic(id: $id, from: $from, to: $to, kind: $kind) {
      rx
      tx
      chart {
        ts
        kind
        value
      }
    }
  }
`;

const SEARCH_POWER_RESET_COMMAND = gql`
  query SearchPowerResetCommandModem($input: SearchInput!) {
    searchMikrotikCommand(input: $input) {
      data {
        name
        command
      }
    }
  }
`;

const SYNC_MODEM = gql`
  mutation SyncModem($id: ID!) {
    syncModem(id: $id)
  }
`;

const renderTarget = (record: any) => {
  return (
    <Space>
      {record.ports.map((x: any) => <div key={x.id}><Link to={`/ports/${x.id}`}><Tag color="blue">{x.id}</Tag></Link></div>)}
      {record.pools.map((x: any) => <div key={x.id}><Link to={`/pools/${x.id}`}><Tag color="purple">{x.name}</Tag></Link></div>)}
    </Space>
  );
};

const gridStyle = {
  width: "50%",
  outline: "none",
};

interface DefaultResetSelectorState {
  name: string;
  filter: string;
  override: boolean;
}

const defaultResetSelectorReducer = (
  state: DefaultResetSelectorState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, name: action.data.mikrotikCommand, override: action.data.mikrotikOverride };
    case "FILTER":
      return { ...state, filter: action.data };
    case "SELECT":
      return { ...state, name: action.data };
    case "TOGGLE_OVERRIDE":
      return { ...state, override: !state.override };
    default:
      throw new Error(`undefined action ${action.type}`);
  }
};

const RouterTrafficTab = (props: { id: string }) => {
  return (
    <Traffic id={props.id} kind="modemRouter" query={MODEM_TRAFFIC} />
  );
};

const WebTrafficTab = (props: { id: string }) => {
  return (
    <Traffic id={props.id} kind="modemExporter" query={MODEM_TRAFFIC} />
  );
};

const tabList = [
  {
    key: "edit",
    tab: "General",
  },
  {
    key: "router",
    tab: "Router traffic",
  },
  {
    key: "web",
    tab: "Web interface traffic",
  },
];

interface ModemEditFields {
  id: string;
  iface: string;
}

export const ModemEdit = () => {
  const { t } = useTranslation("modem");

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [formBase] = Form.useForm<ModemEditFields>();
  const [formReset] = Form.useForm<ModemEditFields>();

  const [selectedTab, selectTab] = useState("edit");
  const [state, dispatch] = useReducer(defaultResetSelectorReducer, { name: "", filter: "", override: false });

  const { loading, data } = useQuery<g.Modem, g.ModemVariables>(GET_MODEM, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.modem });
    }
  });

  const [updateModem] = useMutation<gu.UpdateModem, gu.UpdateModemVariables>(UPDATE_MODEM, {
    okText: i18n.t("modem:Modem updated"),
    refetchQueries: ["Modem"]
  });

  const [deleteModem] = useMutation<gd.DeleteModem, gd.DeleteModemVariables>(DELETE_MODEM, {
    okText: i18n.t("modem:Modem deleted"),
    onCompleted: (_) => {
      history.push("/modems");
    }
  });

  const [syncResult, setSyncResult] = useState("");
  const [syncModem, syncStatus] = useMutation<gs.SyncModem, gs.SyncModemVariables>(SYNC_MODEM, {
    okText: t("Modem synced"),
    refetchQueries: ["Modem"],
    onCompleted: (r) => {
      setSyncResult(r.syncModem);
    }
  });

  const { "data": commandData } = useQuery<gr.SearchPowerResetCommandModem, gr.SearchPowerResetCommandModemVariables>(SEARCH_POWER_RESET_COMMAND, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        filter: state.filter,
        page: 1,
        pageSize: 0,
      }
    }
  });

  if (loading) {
    return <Spin />;
  }

  const editTab = (
    <Fragment>
      <Card>
        <h3>{t("Info")}</h3><br />
        <Row gutter={24}>
          <Col span={8}>
            <Statistic title="Status" value={data?.modem.status} />
          </Col>
          <Col span={8}>
            <Statistic title="IP" value={data?.modem.wanIp} />
          </Col>
          <Col span={8}>
            <Statistic title="Links" valueStyle={{ display: "none" }} />
            {renderTarget(data?.modem)}
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Col span={8}>
            <Statistic title="Perform reset" valueStyle={{ display: "none" }} />
            <ResetActions id={data?.modem.id || ""} refetch="Modem" />
          </Col>
        </Row>
      </Card>
      <br />
      {data?.modem.iProxy && <>
        <Card>
          <h3>{t("iProxy")}</h3><br />
          <Statistic title="Connection ID" value={data?.modem.iProxyData.id} />
          <Statistic title="Proxy" value={data?.modem.iProxyData.proxyUrl} valueStyle={{ fontSize: "1.2em" }} />
          <Statistic title="Change IP" value={data?.modem.iProxyData.changeIpUrl} valueStyle={{ fontSize: "1.2em" }} />
          <br /><br />
          <Button type="primary" loading={syncStatus.loading} onClick={() => syncModem({ variables: { id } })}>{t("Sync iProxy connection")}</Button>
          <br /><br />
          <Typography.Text>{syncResult}</Typography.Text>
        </Card>
        <br />
      </>
      }
      <Card>
        <Card.Grid style={gridStyle} hoverable={false}>
          <h3>{t("Base options")}</h3><br />
          <EditForm
            name="edit-modem-base"
            deleteTitle={i18n.t("modem:Delete modem?")}
            loading={loading}
            form={formBase}
            initialValues={data?.modem}
            onSave={(values) => updateModem({ variables: { id, input: values } })}
            onDelete={() => deleteModem({ variables: { id } })}
          >
            <Form.Item
              name="manufacturer"
              label={i18n.t("modem:Manufacturer")}
            >
              <Select style={{ width: 150 }}>
                <Select.Option value="huawei">Huawei E3372</Select.Option>
                <Select.Option value="huawei525">Huawei B525</Select.Option>
                <Select.Option value="huaweiusa">Huawei USA</Select.Option>
                <Select.Option value="mikrotik">MikroTik</Select.Option>
                <Select.Option value="zte">ZTE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="iface"
              label={i18n.t("modem:Interface")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="proxyPort"
              label={i18n.t("modem:Proxy port")}
              normalize={(value) => String(value)}
            >
              <InputNumber min={1} max={65535} />
            </Form.Item>
            <Form.Item
              name="webHost"
              label={i18n.t("modem:Web interface host")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="webLogin"
              label={i18n.t("modem:Web interface login")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="webPassword"
              label={i18n.t("modem:Web interface password")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="externalIp"
              label={i18n.t("modem:External IP")}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="comment"
              label={i18n.t("modem:Comment")}
            >
              <Input.TextArea />
            </Form.Item>
          </EditForm>
        </Card.Grid>

        <Card.Grid style={gridStyle} hoverable={false}>
          <h3>{t("Reset options")}</h3><br />
          <EditForm
            name="edit-modem-reset"
            loading={loading}
            form={formReset}
            initialValues={data?.modem}
            onSave={(values) => updateModem({ variables: { id, input: { ...values, mikrotikOverride: state.override } } })}
          >
            <Form.Item
              name="resetType"
              label={t("Reset type")}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value="web">{t("Web")}</Radio.Button>
                <Radio.Button value="net">{t("Network")}</Radio.Button>
                <Radio.Button value="power">{t("Power")}</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label={t("Override node settings")}>
              <Checkbox checked={state.override} onChange={() => dispatch({ type: "TOGGLE_OVERRIDE", data: "" })} />
            </Form.Item>
            <Form.Item
              name="mikrotikIp"
              label={t("MikroTik IP")}
            >
              <Input />
            </Form.Item>

            {state.override && <Fragment>

              <Form.Item
                name="mikrotikLogin"
                label={t("Login")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="mikrotikPassword"
                label={t("Password")}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="mikrotikCommand"
                label={t("Command")}
              >
                <Select
                  value={state.name}
                  placeholder={t("Select command")}
                  filterOption={false}
                  showSearch={true}
                  onSearch={(val) => dispatch({ type: "FILTER", data: val })}
                  onSelect={(val: string) => dispatch({ type: "SELECT", data: val })}
                  style={{ width: "100%" }}
                >
                  {commandData?.searchMikrotikCommand.data.map(d => <Select.Option key={d.name} value={d.name}>{d.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Fragment>}
          </EditForm>
        </Card.Grid>
      </Card>
    </Fragment>
  );

  return (
    <Card
      title={data?.modem.id}
      bordered={false}
      headStyle={{ padding: "0" }}
      bodyStyle={{ padding: "24px 0" }}
      tabList={tabList}
      activeTabKey={selectedTab}
      onTabChange={(tabKey) => selectTab(tabKey)}
    >
      {selectedTab === "router" && <RouterTrafficTab id={data!.modem.id} />}
      {selectedTab === "web" && <WebTrafficTab id={data!.modem.id} />}
      {selectedTab === "edit" && editTab}
    </Card>
  );
};
